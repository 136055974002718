// src/App.js
import React, { useState, useEffect, useRef } from 'react';
import { AzureOpenAI } from 'openai';
import ReactMarkdown from 'react-markdown';

function App() {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [assistantId, setAssistantId] = useState(null);
  const [threadId, setThreadId] = useState(null);

  const [loading, setLoading] = useState(false);
  const chatWindowRef = useRef(null); // Ref for the chat window

  // Azure OpenAI API configuration
  const azureOpenAIKey = 'b3000263719d4f67b7629b3b6139fb10';
  const azureOpenAIEndpoint = 'https://ai-demohub163047383877.openai.azure.com/';
  const azureOpenAIVersion = "2024-05-01-preview";

  // Initialize Azure OpenAI client
  const openAIClient = new AzureOpenAI({
    endpoint: azureOpenAIEndpoint,
    apiVersion: azureOpenAIVersion,
    apiKey: azureOpenAIKey,
    dangerouslyAllowBrowser: true,
  });

  useEffect(() => {
    // Function to load assistant and create a thread on component mount
    const initializeAssistantAndThread = async () => {
      try {
        // Define the options for creating the assistant
        const options = {
          //model: 'gpt-4o-mini', // Replace with your deployed model name
          model: "gpt-4o",
          name: 'DEMO_ASSIST1',
          
          instructions: "You are very good assistant that got knowledge about insurance policy and plan. Answer only information you have a files.",
          tools: [{"type":"file_search"}],
          
          tool_resources: {"file_search":{"vector_store_ids":["vs_I5SlE8fAhxO8Bjwbgb2xeoDZ"]}},
          
          temperature: 1,
          top_p: 1,
        };

        // Create the assistant
        const assistantResponse = await openAIClient.beta.assistants.create(options);
        console.log(`Assistant created: ${JSON.stringify(assistantResponse)}`);
        setAssistantId(assistantResponse.id); // Store the assistant ID

        // Create a new thread
        const assistantThread = await openAIClient.beta.threads.create({});
        console.log(`Thread created: ${JSON.stringify(assistantThread)}`);
        setThreadId(assistantThread.id); // Store the thread ID
      } catch (error) {
        console.error(`Error initializing assistant or thread: ${error.message}`);
      }
    };

    initializeAssistantAndThread(); // Call the initialization function when the component mounts
  }, []);

  useEffect(() => {
    // Scroll to the bottom of the chat window whenever messages change
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!input.trim() || !assistantId || !threadId) return;

    // Add the user's message to the chat
    const userMessage = { role: 'user', content: input };
    setMessages([...messages, userMessage]);
    setInput('...');

    try {
      // Define the options for the assistant call
      const options = {
        messages: [...messages, userMessage], // Send the full message history
        temperature: 1,
        top_p: 1,
      };

      // Add a user message to the thread
      const threadResponse = await openAIClient.beta.threads.messages.create(threadId, {
        role: 'user',
        content: input,
      });
      console.log(`Message created: ${JSON.stringify(threadResponse)}`);

      // Run the thread
      const runResponse = await openAIClient.beta.threads.runs.create(threadId, {
        assistant_id: assistantId,
      });
      console.log(`Run started: ${JSON.stringify(runResponse)}`);

      // Polling until the run completes or fails
      let runStatus = runResponse.status;
      while (runStatus === 'queued' || runStatus === 'in_progress') {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const runStatusResponse = await openAIClient.beta.threads.runs.retrieve(
          threadId,
          runResponse.id
        );
        runStatus = runStatusResponse.status;
        console.log(`Current run status: ${runStatus}`);
      }

      // Get the messages in the thread once the run has completed
      if (runStatus === 'completed') {
        try {
          // Retrieve messages from the thread
          const messagesResponse = await openAIClient.beta.threads.messages.list(threadId);
          console.log(`Messages retrieved: ${JSON.stringify(messagesResponse)}`);
          
          // Extract the messages array from the response
          const retrievedMessages = messagesResponse.data; // Accessing messages from the 'data' field
      
          // Find the latest assistant message from the response
          const assistantMessage = retrievedMessages.find(
            (msg) => msg.role === 'assistant'
          );
      
          // If an assistant message is found, update the state
          if (assistantMessage) {
            // The content seems to be inside an array with type and text objects
            const assistantContent = assistantMessage.content.map(content => content.text.value).join(' '); // Extracting and joining text content
      
            setMessages((prevMessages) => [
              ...prevMessages,
              { role: 'assistant', content: assistantContent },
            ]);
          }
        } catch (error) {
          console.error('Error retrieving messages from Azure OpenAI:', error);
          alert('Failed to retrieve messages. Please try again.');
        }
      } else {
        console.log(`Run status is ${runStatus}, unable to fetch messages.`);
      }
    } catch (error) {
      console.error('Error fetching data from Azure OpenAI:', error);
      alert('There was an error with the request. Please try again.');
    } finally {
      setLoading(false);
      setInput('');
    }

  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
      <h1>Chat with - Allianz insurance policy</h1>
      <div


        ref={chatWindowRef} // Reference to the chat window
        style={{
          marginBottom: '20px',
          border: '1px solid #ccc',
          padding: '10px',
          borderRadius: '5px',
          height: '650px',
          overflowY: 'scroll',
        }}
      >
        {messages.map((message, index) => (
          <div
            key={index}
            style={{ margin: '10px 0', textAlign: message.role === 'user' ? 'right' : 'left' }}
          >
            <strong>{message.role === 'user' ? 'You' : 'Allicy'}: </strong>
            <ReactMarkdown>{message.content}</ReactMarkdown>
          </div>
        ))}
        {loading && (
          <div style={{ textAlign: 'center', color: '#999', fontStyle: 'italic' }}>
            Loading...
          </div>
          )}
      </div>
      <form onSubmit={handleSubmit} style={{ display: 'flex', gap: '10px' }}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message..."
          style={{ flex: 1, padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
        />
        <button
          type="submit"
          style={{
            padding: '10px 20px',
            borderRadius: '5px',
            border: 'none',
            backgroundColor: '#007bff',
            color: '#fff',
          }}
        >
          Send
        </button>
      </form>
    </div>
  );
}

export default App;
